import React, { useState } from 'react';

const ExternalLinkIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
    <polyline points="15 3 21 3 21 9"></polyline>
    <line x1="10" y1="14" x2="21" y2="3"></line>
  </svg>
);

const CoinGatePadLanding = () => {
  const socialLinks = [
    { name: 'Telegram Channel', url: 'https://t.me/CoinGatePad' },
    { name: 'Telegram Group', url: 'https://t.me/CoinGatePadChat' },
    { name: 'X', url: 'https://x.com/CoinGatePad' },
    { name: 'Discord', url: 'https://discord.gg/pdzrW9sPr2' },
  ];

  const roadmap = [
    {
      phase: 'Q3 2024',
      items: [
        'Market research and competitive analysis on chains',
        'Team works on a Marketing strategy',
        'Build a user-friendly Telegram Mini App Play-To-Earn',
        'Adding functionality and features to Mini App',
        'Run tests on the platform ensuring it is secure and functional',
        'Expansion of CoinGatePad Team',
        'Establish partnerships with reputable projects and influencers',
        'Reward program for community contributors'
      ]
    },
    {
      phase: 'Q4 2024',
      items: [
        'Global expansion to bring in a wider range of communities',
        'Major partnerships & collaboration to bring more exposure',
        'Beta Launch with test features',
        'Bonus structures and incentives to reward participants and encourage wider participation'
      ]
    },
    {
      phase: 'Q1 - Q2 2025',
      items: [
        'Full Platform Launch',
        'Token Generation Event and Listing',
        'Multi-Chain Integration and Expansion'
      ]
    }
  ];

  const [activePhase, setActivePhase] = useState('Q3 2024');

  const styles = {
    container: { minHeight: '100vh', backgroundColor: 'black', color: 'white', fontFamily: 'sans-serif' },
    header: { padding: '1rem', display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
    nav: { display: 'flex', gap: '1rem' },
    link: { color: '#4ade80', textDecoration: 'none' },
    main: { maxWidth: '1200px', margin: '0 auto', padding: '0 1rem' },
    section: { paddingTop: '4rem', paddingBottom: '4rem' },
    title: { fontSize: '3rem', fontWeight: 'bold', marginBottom: '1rem' },
    button: { backgroundColor: '#4ade80', color: 'black', padding: '0.5rem 1rem', borderRadius: '9999px', textDecoration: 'none', display: 'inline-block' },
    footer: { backgroundColor: '#111827', padding: '1rem', textAlign: 'center' },
    roadmapButton: { backgroundColor: '#333', color: 'white', padding: '0.5rem 1rem', borderRadius: '9999px', border: 'none', cursor: 'pointer', margin: '0 0.5rem 1rem 0' },
    activeRoadmapButton: { backgroundColor: '#4ade80', color: 'black' },
    roadmapItem: { marginBottom: '0.5rem', display: 'flex', alignItems: 'start' },
    roadmapBullet: { color: '#4ade80', marginRight: '0.5rem' },
  };

  return (
    <div style={styles.container}>
      <header style={styles.header}>
        <img src="/logo.png" alt="CoinGatePad Logo" style={{ height: '3rem' }} />
        <nav style={styles.nav}>
          <a href="#about" style={styles.link}>About</a>
          <a href="#roadmap" style={styles.link}>Roadmap</a>
          <a href="#socials" style={styles.link}>Socials</a>
        </nav>
      </header>

      <main style={styles.main}>
        <section style={{...styles.section, textAlign: 'center'}}>
          <h1 style={styles.title}>CoinGatePad</h1>
          <p style={{fontSize: '1.25rem', marginBottom: '2rem'}}>One Platform, Infinite Chains, Boundless Potential</p>
          <a href="https://t.me/CoinGatePadBot" style={styles.button}>Play Now</a>
        </section>

        <section id="about" style={styles.section}>
          <h2 style={{...styles.title, fontSize: '2rem'}}>About CoinGatePad</h2>
          <p style={{fontSize: '1.125rem', marginBottom: '1rem'}}>
            CoinGatePad is a decentralized exchange (DEX) launchpad for the world of cryptocurrencies across multiple chains. We unlock limitless opportunities, offering seamless fundraising and broad exposure across the crypto ecosystem.
          </p>
          <a href="CoinGatePad-Whitepaper.pdf" style={{...styles.link, display: 'inline-flex', alignItems: 'center'}}>
            Read Whitepaper <ExternalLinkIcon />
          </a>
        </section>

        <section id="roadmap" style={styles.section}>
          <h2 style={{...styles.title, fontSize: '2rem'}}>Roadmap</h2>
          <div>
            {roadmap.map((phase) => (
              <button
                key={phase.phase}
                style={{
                  ...styles.roadmapButton,
                  ...(activePhase === phase.phase ? styles.activeRoadmapButton : {})
                }}
                onClick={() => setActivePhase(phase.phase)}
              >
                {phase.phase}
              </button>
            ))}
          </div>
          <ul style={{listStyleType: 'none', padding: 0}}>
            {roadmap.find(phase => phase.phase === activePhase)?.items.map((item, index) => (
              <li key={index} style={styles.roadmapItem}>
                <span style={styles.roadmapBullet}>•</span>
                {item}
              </li>
            ))}
          </ul>
        </section>

        <section id="socials" style={styles.section}>
          <h2 style={{...styles.title, fontSize: '2rem'}}>Connect With Us</h2>
          <div style={{display: 'flex', flexWrap: 'wrap', gap: '1rem'}}>
            {socialLinks.map((link, index) => (
              <a
                key={index}
                href={link.url}
                target="_blank"
                rel="noopener noreferrer"
                style={{...styles.button, display: 'inline-flex', alignItems: 'center', gap: '0.5rem'}}
              >
                {link.name} <ExternalLinkIcon />
              </a>
            ))}
          </div>
        </section>
      </main>

      <footer style={styles.footer}>
        <p>&copy; 2024 CoinGatePad. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default CoinGatePadLanding;